import { createStore } from 'vuex'
import ConfigModule from '@/store/modules/ConfigModule'
import ThemeModeModule from '@/store/modules/ThemeModeModule'
import BodyModule from '@/store/modules/BodyModule'
import UserModule from '@/store/modules/UserModule'

const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
    initializeStore (state) {
      const storageState = localStorage.getItem('store')
      if (storageState) {
        this.replaceState(
          Object.assign(state, JSON.parse(storageState))
        )
      }
    }
  },
  actions: {
  },
  modules: {
    ConfigModule,
    ThemeModeModule,
    BodyModule,
    UserModule
  }
})

store.subscribe((mutation, state) => {
  localStorage.setItem('store', JSON.stringify(state))
})

export default store
