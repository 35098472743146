import messagesFR from './fr_FR.lang'
import messagesEN from './en_US.lang'
import { createI18n } from 'vue-i18n'

const I18N_MESSAGES = {
  'fr-FR': messagesFR,
  'en-US': messagesEN
}

const i18n = createI18n({
  legacy: false,
  locale: 'fr-FR',
  fallbackLocale: [
    'en-US'
  ],
  messages: { ...I18N_MESSAGES }
})

export { i18n }
