
import { defineComponent, nextTick, onMounted } from 'vue'
import MainLayout from '@/components/layout/MainLayout.vue'
import { useStore } from 'vuex'
import { themeMode } from '@/config/config'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
// import { initializeComponents } from '@/assets/metronik/ts/keenthemes'

export default defineComponent({
  name: 'app',
  components: {
    MainLayout
  },
  setup () {
    const store = useStore()
    store.commit('initializeStore')

    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)

      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value)

      nextTick(() => {
        // initializeComponents()

        // Intégrez ici les routines à exécuter au démarrage de l'application: login, setup de thème, etc.

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })
    })
  }
})

