import LayoutConfigTypes from '@/types/layout'

const config: LayoutConfigTypes = {
  general: {
    mode: 'light',
    primaryColor: '#50CD89',
    pageWidth: 'default',
    layout: 'light-header'
  },
  header: {
    display: true,
    default: {
      container: 'fluid',
      fixed: {
        desktop: true,
        mobile: false
      },
      menu: {
        display: false,
        iconType: 'svg'
      }
    }
  },
  sidebar: {
    display: false,
    default: {
      minimize: {
        desktop: {
          enabled: true,
          default: false,
          hoverable: true
        }
      },
      menu: {
        iconType: 'svg'
      }
    }
  },
  toolbar: {
    display: false,
    container: 'fluid',
    fixed: {
      desktop: false,
      mobile: false
    }
  },
  pageTitle: {
    display: true,
    breadcrumb: true,
    direction: 'column'
  },
  content: {
    container: 'fluid'
  },
  footer: {
    display: true,
    container: 'fluid',
    fixed: {
      desktop: false,
      mobile: false
    }
  },
  illustrations: {
    set: 'sketchy-1'
  },
  scrolltop: {
    display: true
  }
}

export default config
