enum Actions {
    // action types
    CONFIGURE_AXIOS_AUTHENTICATION = 'configureAxiosAuthentication',
    GET_USER_BY_JWT = 'getUserByJwt',
    ADD_BODY_CLASSNAME = 'addBodyClassName',
    REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
    ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
    REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
    ADD_CLASSNAME = 'addClassName',
    VERIFY_AUTH = 'verifyAuth',
    LOGIN = 'login',
    LOGOUT = 'logout',
    REGISTER = 'register',
    UPDATE_USER = 'updateUser',
    FORGOT_PASSWORD = 'forgotPassword',
    SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
    SET_THEME_MODE_ACTION = 'setThemeModeAction',
}

enum Mutations {
    // mutation types
    LOGOUT_CURRENT_USER = 'logoutCurrentUser',
    SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
    PURGE_AUTH = 'logOut',
    SET_AUTH = 'setAuth',
    SET_USER = 'setUser',
    SET_PASSWORD = 'setPassword',
    SET_ERROR = 'setError',
    SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
    SET_LAYOUT_CONFIG_PROPERTY = 'setLayoutConfigProperty',
    RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
    OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
    OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
    SET_THEME_MODE_MUTATION = 'setThemeModeMutation',

    SAVE_LOGGED_USER = 'saveLoggedUser',
}

export { Actions, Mutations }
