import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { i18n } from '@/config/i18n'
import { computed, ComputedRef } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // code-splitting au niveau de la route
    // permet de générer un chunk différent par route, pour favoriser le lazy-loading et le temps de chargement initial
    // à utiliser de la même manière sur toutes les routes
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.home'))
    }
  },
  {
    path: '/sign/:id',
    name: 'sign',
    // code-splitting au niveau de la route
    // permet de générer un chunk différent par route, pour favoriser le lazy-loading et le temps de chargement initial
    // à utiliser de la même manière sur toutes les routes
    component: () => import(/* webpackChunkName: "home" */ '../views/SignView.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.sign'))
    },
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title && (<ComputedRef<string>>to.meta.title).value) {
    document.title = (to.meta.title ? (to.meta.title as ComputedRef<string>).value + ' - ' : '') + i18n.global.t('global.title')
  } else {
    document.title = (to.meta.title ? to.meta.title + ' - ' : '') + i18n.global.t('global.title')
  }

  next()
})

export default router
