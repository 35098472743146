
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'header-navbar',
  setup () {
    const store = useStore()

    const themeMode = computed(() => {
      return store.getters.getThemeMode
    })

    return {
      themeMode
    }
  },
  computed: {
    user () {
      return useStore().getters.getUser
    }
  }
})
