
import { defineComponent } from 'vue'
import KTHeaderMenu from '@/components/layout/header/menu/Menu.vue'
import KTHeaderNavbar from '@/components/layout/header/Navbar.vue'
import {
  layout,
  headerWidthFluid,
  themeMode,
  headerDisplay
} from '@/config/config'

export default defineComponent({
  name: 'layout-header',
  components: {
    KTHeaderMenu,
    KTHeaderNavbar
  },
  setup () {
    return {
      layout,
      headerWidthFluid,
      headerDisplay,
      themeMode
    }
  }
})
