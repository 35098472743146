export default {
  meta: {
    titles: {
      home: 'Home',
      sign: 'Signature process'
    }
  },
  global: {
    title: 'Signature · Léonard de Vinci'
  }
}
