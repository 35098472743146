import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'

export interface StoreInfo {
  classes: {
    header?: Array<string>;
    headerContainer?: Array<string>;
    headerMobile?: Array<string>;
    headerMenu?: Array<string>;
    aside?: Array<string>;
    asideMenu?: Array<string>;
    asideToggle?: Array<string>;
    toolbar?: Array<string>;
    toolbarContainer?: Array<string>;
    content?: Array<string>;
    contentContainer?: Array<string>;
    footerContainer?: Array<string>;
    sidebar?: Array<string>;
    pageTitle?: Array<string>;
  };
}

type BodyPayload = {
  qualifiedName: string,
  position: keyof StoreInfo['classes']
  className: string
  value: string,
}

@Module
export default class BodyModule extends VuexModule implements StoreInfo {
  classes: StoreInfo['classes'] = {};

  get getClasses () {
    return (position: BodyPayload['position']) => {
      if (typeof position !== 'undefined') {
        return this.classes[position]
      }
      return this.classes
    }
  }

  @Mutation
  [Mutations.SET_CLASSNAME_BY_POSITION] (payload: BodyPayload) {
    const { position, className } = payload
    if (!this.classes[position]) {
      this.classes[position] = []
    }
    this.classes[position]?.push(className)
  }

  @Action
  [Actions.ADD_BODY_CLASSNAME] (className: string) {
    document.body.classList.add(className)
  }

  @Action
  [Actions.REMOVE_BODY_CLASSNAME] (className: string) {
    document.body.classList.remove(className)
  }

  @Action
  [Actions.ADD_BODY_ATTRIBUTE] (payload: BodyPayload) {
    const { qualifiedName, value } = payload
    document.body.setAttribute(qualifiedName, value)
  }

  @Action
  [Actions.REMOVE_BODY_ATTRIBUTE] (payload: BodyPayload) {
    const { qualifiedName } = payload
    document.body.removeAttribute(qualifiedName)
  }

  @Action
  [Actions.ADD_CLASSNAME] (payload: BodyPayload) {
    this.context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload)
  }
}
