import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column flex-root app-root",
  id: "kt_app_root"
}
const _hoisted_2 = {
  class: "app-page flex-column flex-column-fluid",
  id: "kt_app_page"
}
const _hoisted_3 = {
  class: "app-wrapper flex-column flex-row-fluid",
  id: "kt_app_wrapper"
}
const _hoisted_4 = {
  class: "app-main flex-column flex-row-fluid",
  id: "kt_app_main"
}
const _hoisted_5 = { class: "d-flex flex-column flex-column-fluid" }
const _hoisted_6 = {
  id: "kt_app_content",
  class: "app-content flex-column-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTContent = _resolveComponent("KTContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTHeader),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_KTContent)
            ])
          ])
        ])
      ])
    ])
  ]))
}