import { i18n } from '@/config/i18n'
import { computed } from 'vue'

export default [
  {
    pages: [
      {
        heading: computed(() => i18n.global.t('menu.home.home')),
        route: '/'
      },
      {
        heading: computed(() => i18n.global.t('menu.home.about')),
        route: '/about'
      }
    ]
  }
]
