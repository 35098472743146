import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { UserResponse } from '@/types/api-response'
import ApiService from '@/services/ApiService'

@Module
export default class UserModule extends VuexModule {
  user: UserResponse = {
    userType: 'anon'
  }

  // noinspection JSUnusedGlobalSymbols
  get getUser () {
    return this.user
  }

  // noinspection JSUnusedGlobalSymbols
  get isLoggedIn () {
    return this.user.userType !== 'anon'
  }

  @Mutation
  [Mutations.LOGOUT_CURRENT_USER] () {
    this.user = {
      userType: 'anon'
    }
    // TODO: Dispatch sur oauth en prod
  }

  @Mutation
  [Mutations.SAVE_LOGGED_USER] (payload: UserResponse) {
    this.user = payload
  }

  @Action
  [Actions.CONFIGURE_AXIOS_AUTHENTICATION] (payload: string) {
    ApiService.vueInstance.axios.defaults.headers.common.Authorization = 'Bearer ' + payload
  }

  @Action
  [Actions.GET_USER_BY_JWT] (payload: string) {
    ApiService.get<UserResponse>('/v2/users/me').then((res) => {
      if (res && res.data) {
        res.data.token = payload
        this.context.commit(Mutations.SAVE_LOGGED_USER, res.data)
      }
    })
  }
}
